import React, { useState } from "react";
import { useParams } from "react-router-dom";

export default function Reset() {
    const [newPassword, setNewPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const { token } = useParams(); // Get the token from the URL

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!newPassword) {
            setPasswordError(true);
            return;
        }

        try {
            // Send the token and newPassword to the backend
            const response = await fetch(
                "https://api.secretsanta.tv/api/users/reset",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ token, newPassword }),
                }
            );

            const json = await response.json();

            if (response.ok) {
                setSuccessMessage("Your password has been successfully reset.");
            } else {
                console.error(json.message);
            }
        } catch (error) {
            console.error("Error resetting password", error);
        }
    };

    const inputClassName = (error) =>
        `block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6${
            error ? " border-2 border-rose-500" : ""
        }`;

    const errorMessage = (error, message) => {
        if (error) {
            return <p className="mt-2 text-sm text-red-500">{message}</p>;
        }
        return null;
    };

    return (
        <>
            <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-12 w-auto"
                        src="/logo512.png"
                        alt="Your Company"
                    />
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                        Reset Your Password
                    </h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        Please enter your new password below.
                    </p>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        {successMessage ? (
                            <p className="text-green-600">{successMessage}</p>
                        ) : (
                            <form className="space-y-4" onSubmit={handleSubmit}>
                                <div>
                                    <label
                                        htmlFor="newPassword"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        New Password
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            onChange={(e) => {
                                                setNewPassword(e.target.value);
                                                setPasswordError(false);
                                            }}
                                            value={newPassword}
                                            type="password"
                                            name="newPassword"
                                            id="newPassword"
                                            autoComplete="new-password"
                                            className={inputClassName(
                                                passwordError
                                            )}
                                        />
                                    </div>
                                    {errorMessage(
                                        passwordError,
                                        "Please enter a new password"
                                    )}
                                </div>

                                <div>
                                    <button
                                        type="submit"
                                        className="mt-8 flex w-full justify-center rounded-md bg-red-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                    >
                                        Reset Password
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <p className="mt-6 text-center text-sm text-gray-600">
                        <a
                            href="/login"
                            className="font-medium text-red-600 hover:text-red-500"
                        >
                            Return to login page
                        </a>
                    </p>
                </div>
            </div>
        </>
    );
}
