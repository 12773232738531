import {
    createContext,
    useReducer,
    useEffect,
    useState,
    useContext,
} from "react";
import { jwtDecode } from "jwt-decode";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            return { user: action.payload };
        case "LOGOUT":
            return { user: null };
        default:
            return state;
    }
};

export const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, { user: null });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const storedUser = localStorage.getItem("user");

        if (storedUser) {
            const user = JSON.parse(storedUser);

            // Decode the token and check if it is expired
            const decodedToken = jwtDecode(user.token);
            if (decodedToken.exp * 1000 < Date.now()) {
                dispatch({ type: "LOGOUT" });
            } else {
                dispatch({ type: "LOGIN", payload: user });
            }
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (state.user) {
            localStorage.setItem("user", JSON.stringify(state.user));
        } else {
            localStorage.removeItem("user");
        }
    }, [state.user]);

    const onLogout = () => {
        dispatch({ type: "LOGOUT" });
    };

    return (
        <AuthContext.Provider value={{ ...state, dispatch, onLogout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const { user, loading } = useContext(AuthContext);
    return { user, loading };
};
