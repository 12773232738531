import { Link } from "react-router-dom";

export default function Subheader({ subheaderTitle }) {
    return (
        <>
            <div className="border-b border-gray-200 pb-5 flex justify-between">
                <div>
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                        {subheaderTitle}
                    </h3>
                </div>
                <div>
                    {subheaderTitle === "Groups" && (
                        <Link
                            to="/groups/create"
                            type="button"
                            className="inline-flex items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        >
                            Create Group
                        </Link>
                    )}
                    {subheaderTitle === "Create Group" && (
                        <>
                            <Link
                                to="/groups"
                                type="button"
                                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                Cancel
                            </Link>
                            <Link
                                to="/groups/create"
                                type="button"
                                className="ml-3 inline-flex items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                            >
                                Save
                            </Link>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
