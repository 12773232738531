import { useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";

export default function MyGiftRequestList({ gift }) {
    const { user } = useAuthContext();
    const [edit, setEdit] = useState(false);
    const [title, setTitle] = useState("");
    const [price, setPrice] = useState("");
    const [url, setUrl] = useState("");

    const handleEdit = () => {
        setTitle(gift.title);
        setPrice(gift.price);
        setUrl(gift.url);
        setEdit(!edit);
    };

    const handleCancel = () => {
        setEdit(!edit);
    };

    const handleDelete = async () => {
        if (!user) {
            return;
        }

        const response = await fetch(
            "https://api.secretsanta.tv/api/gifts/" + gift._id,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user.token}`,
                },
            }
        );

        if (response.ok) {
            window.location.reload();
        }
    };

    const handleSave = async () => {
        if (!user) {
            return;
        }

        const updateGift = { title, price, url };

        const response = await fetch(
            "https://api.secretsanta.tv/api/gifts/" + gift._id,
            {
                method: "PATCH",
                body: JSON.stringify(updateGift),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user.token}`,
                },
            }
        );

        if (response.ok) {
            window.location.reload();
        }
        setEdit(!edit);
    };

    return (
        <>
            <tr key={gift._id}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {edit ? (
                        <input
                            type="text"
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                            value={title}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
                        />
                    ) : (
                        <p>{gift.title}</p>
                    )}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {edit ? (
                        <input
                            type="text"
                            onChange={(e) => {
                                setPrice(e.target.value);
                            }}
                            value={price}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
                        />
                    ) : (
                        <p>${gift.price}</p>
                    )}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {edit ? (
                        <input
                            type="text"
                            onChange={(e) => {
                                setUrl(e.target.value);
                            }}
                            value={url}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
                        />
                    ) : (
                        <a
                            href={gift.affiliateUrl || gift.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-red-600 hover:text-red-900 underline cursor-pointer"
                        >
                            Link
                        </a>
                    )}
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-6">
                    <span className="text-red-600 hover:text-red-900 cursor-pointer mr-4">
                        {edit ? (
                            <span
                                className="text-red-600 hover:text-red-900 cursor-pointer"
                                onClick={handleCancel}
                            >
                                Cancel
                            </span>
                        ) : (
                            <span onClick={handleEdit}>Edit</span>
                        )}
                    </span>
                    {edit ? (
                        <span
                            className="text-red-600 hover:text-red-900 font-medium cursor-pointer"
                            onClick={handleSave}
                            id={gift._id}
                        >
                            Save
                        </span>
                    ) : (
                        <span
                            onClick={handleDelete}
                            className="text-red-600 hover:text-red-900 font-medium cursor-pointer"
                            id={gift._id}
                        >
                            Delete
                        </span>
                    )}
                </td>
            </tr>
        </>
    );
}
