import React from "react";
import { useAuth } from "./context/AuthContext";
import { Navigate } from "react-router-dom";

const withAuth = (Component) => {
    return (props) => {
        const { user, loading } = useAuth();

        if (loading) {
            return <div>Loading...</div>;
        }

        if (!user) {
            return <Navigate to="/login" />;
        }

        return <Component {...props} />;
    };
};

export default withAuth;
