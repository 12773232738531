import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./pages/Error";
import Home from "./pages/Home";
import Gifts from "./components/MyGifts/Gifts";
import Groups from "./components/Groups/Groups";
import CreateGroup from "./components/Groups/CreateGroup";
import Login from "./pages/Login";
import Reset from "./pages/Reset";
import Forgot from "./pages/Forgot";
import Signup from "./pages/Signup";
import { UsersContextProvider } from "./context/UsersContext";
import { AuthContextProvider } from "./context/AuthContext";
import withAuth from "./withAuth";
import withoutAuth from "./withoutAuth";

const UnauthenticatedLogin = withoutAuth(Login);
const UnauthenticatedForgot = withoutAuth(Forgot);
const UnauthenticatedReset = withoutAuth(Reset);
const UnauthenticatedSignup = withoutAuth(Signup);
const AuthenticatedGifts = withAuth(Gifts);
const AuthenticatedGroups = withAuth(Groups);
const AuthenticatedCreateGroup = withAuth(() => (
    <UsersContextProvider>
        <CreateGroup />
    </UsersContextProvider>
));

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <Home />,
            },
            {
                path: "login",
                element: <UnauthenticatedLogin />,
            },
            {
                path: "forgot",
                element: <UnauthenticatedForgot />,
            },
            {
                path: "reset/:token",
                element: <UnauthenticatedReset />,
            },
            {
                path: "signup",
                element: <UnauthenticatedSignup />,
            },
            {
                path: "gifts",
                element: <AuthenticatedGifts />,
            },
            {
                path: "groups",
                element: <AuthenticatedGroups />,
            },
            {
                path: "groups/create",
                element: <AuthenticatedCreateGroup />,
            },
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <AuthContextProvider>
            <RouterProvider router={router} />
        </AuthContextProvider>
    </React.StrictMode>
);
