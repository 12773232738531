import { useState, useEffect } from "react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { useAuthContext } from "../hooks/useAuthContext";
import isEmail from "validator/lib/isEmail";

// Add validation functions
const validateEmail = (email) => {
    return isEmail(email);
};

const validatePassword = (password) => {
    // Add any password validation rules you need, e.g., minimum length
    return password.length >= 6;
};

export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [invalidCredentialsError, setInvalidCredentialsError] =
        useState(false);
    const { dispatch } = useAuthContext();

    useEffect(() => {
        // Get the query string from the URL
        const queryString = window.location.search;

        // Parse the query string into an object
        const urlParams = new URLSearchParams(queryString);

        // Set the state variables with the values from the query parameters
        const urlEmail = urlParams.get("email");

        if (urlEmail) setEmail(urlEmail);

        document.title = "Login - Secret Santa";
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const emailIsValid = validateEmail(email);
        const passwordIsValid = validatePassword(password);

        setEmailError(!emailIsValid);
        setPasswordError(!passwordIsValid);

        if (!emailIsValid || !passwordIsValid) {
            return;
        }

        try {
            const response = await fetch(
                "https://api.secretsanta.tv/api/users/login",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        email,
                        password,
                    }),
                }
            );

            const json = await response.json();

            if (response.ok) {
                localStorage.setItem("user", JSON.stringify(json));
                dispatch({ type: "LOGIN", payload: json });
                window.location.replace("/");
            } else {
                console.error(json.message);
                if (json.message === "Invalid email or password") {
                    setInvalidCredentialsError(true);
                }
            }
        } catch (error) {}
    };

    const inputClassName = (error) =>
        `block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6${
            error ? " border-2 border-rose-500" : ""
        }`;

    const errorMessage = (error, message) => {
        if (error) {
            return <p className="mt-2 text-sm text-red-500">{message}</p>;
        }
        return null;
    };

    return (
        <>
            <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-12 w-auto"
                        src="/logo512.png"
                        alt="Your Company"
                    />
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                        Log in to your account
                    </h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        Or{" "}
                        <a
                            href="/signup"
                            className="font-medium text-red-600 hover:text-red-500"
                        >
                            Sign up for an account
                        </a>
                    </p>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-4" onSubmit={handleSubmit}>
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <input
                                        onChange={(e) => {
                                            setEmail(
                                                e.target.value.toLowerCase()
                                            ); // Convert email to lowercase
                                            setEmailError(false);
                                        }}
                                        value={email}
                                        type="text"
                                        name="email"
                                        id="email"
                                        autoComplete="email"
                                        className={inputClassName(emailError)}
                                    />
                                </div>
                                {errorMessage(emailError, "Incorrect email")}
                            </div>

                            <div>
                                <label
                                    htmlFor="password"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Password
                                </label>
                                <div className="mt-2">
                                    <input
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                            setPasswordError(false);
                                        }}
                                        value={password}
                                        type="password"
                                        name="password"
                                        id="password"
                                        autoComplete="password"
                                        className={inputClassName(
                                            passwordError
                                        )}
                                    />
                                </div>
                                {errorMessage(
                                    passwordError,
                                    "Incorrect password"
                                )}
                                {invalidCredentialsError && (
                                    <div className="rounded-md bg-red-50 p-4 mt-4">
                                        <div className="flex">
                                            <div className="flex-shrink-0">
                                                <XCircleIcon
                                                    className="h-5 w-5 text-red-400"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div className="ml-3">
                                                <h3 className="text-sm font-medium text-red-800">
                                                    Invalid email or password
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="mt-8 flex w-full justify-center rounded-md bg-red-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                >
                                    Log in
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <p className="mt-6 text-center text-sm text-gray-600">
                        <a
                            href="/forgot"
                            className="font-medium text-red-600 hover:text-red-500"
                        >
                            Forgot password?
                        </a>
                    </p>
                </div>
            </div>
        </>
    );
}
