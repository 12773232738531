import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavLink, useLocation } from "react-router-dom";
import { useLogout } from "../hooks/useLogout.js";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

let active =
    "inline-flex items-center border-b-2 border-red-500 px-1 pt-1 text-sm font-medium text-gray-900";

let inactive =
    "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700";

export default function Nav() {
    const location = useLocation();

    const isActive = (href) => {
        return location.pathname === href
            ? "border-red-500 bg-red-50 py-2 pl-3 pr-4 text-base font-medium text-red-700"
            : "border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700";
    };

    const { logout } = useLogout();

    const handleLogout = () => {
        logout();
        window.location.replace("/login");
    };

    return (
        <Disclosure as="nav" className="bg-white shadow">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                        <div className="relative flex h-16 justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500">
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    {open ? (
                                        <XMarkIcon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <Bars3Icon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex flex-shrink-0 items-center">
                                    <NavLink to={`/`}>
                                        <img
                                            className="block h-8 w-auto lg:hidden"
                                            src="/logo512.png"
                                            alt="Your Company"
                                        />
                                    </NavLink>
                                    <NavLink to={`/`}>
                                        <img
                                            className="hidden h-8 w-auto lg:block"
                                            src="/logo512.png"
                                            alt="Your Company"
                                        />
                                    </NavLink>
                                </div>
                                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                                    {/* Current: "border-red-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                                    <NavLink
                                        to={`/`}
                                        className={({ isActive }) =>
                                            isActive ? active : inactive
                                        }
                                    >
                                        Gift Requests
                                    </NavLink>
                                    <NavLink
                                        to={`gifts`}
                                        href="#"
                                        className={({ isActive }) =>
                                            isActive ? active : inactive
                                        }
                                    >
                                        My Gifts
                                    </NavLink>
                                    <NavLink
                                        to={`groups`}
                                        className={({ isActive }) =>
                                            isActive ? active : inactive
                                        }
                                    >
                                        Groups
                                    </NavLink>
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                {/* Profile dropdown */}
                                <Menu as="div" className="relative ml-3">
                                    <div>
                                        <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
                                            <span className="sr-only">
                                                Open user menu
                                            </span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="h-6 w-6 rounded-full"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                                                />
                                            </svg>
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="#"
                                                        onClick={handleLogout}
                                                        className={classNames(
                                                            active
                                                                ? "bg-gray-100"
                                                                : "",
                                                            "block px-4 py-2 text-sm text-gray-700"
                                                        )}
                                                    >
                                                        Sign out
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 pt-2 pb-4">
                            <Disclosure.Button
                                as="a"
                                href="/"
                                className={`block border-l-4 ${isActive("/")}`}
                            >
                                Gift Requests
                            </Disclosure.Button>
                            <Disclosure.Button
                                as="a"
                                href="/gifts"
                                className={`block border-l-4 ${isActive(
                                    "/gifts"
                                )}`}
                            >
                                My Gifts
                            </Disclosure.Button>
                            <Disclosure.Button
                                as="a"
                                href="/groups"
                                className={`block border-l-4 ${isActive(
                                    "/groups"
                                )}`}
                            >
                                Groups
                            </Disclosure.Button>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}
